import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.scrollY = window.scrollY

    if (this.scrollY > this.element.offsetHeight) {
      this.element.classList.add('is-stuck')
    }
  }

  scroll() {
    this.scrollY = window.scrollY

    if (this.scrollY > this.element.offsetHeight) {
      this.element.classList.add('is-stuck')
    } else {
      this.element.classList.remove('is-stuck')
    }
  }
}
