import Turbolinks from 'turbolinks'
import SweetScroll from 'sweet-scroll'
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import 'alpinejs'

const application = Application.start()
const context = require.context('./controllers', true, /\.js$/)
application.load(definitionsFromContext(context))

import '../css/application.scss'
require.context('../images', true)

Turbolinks.start()

document.addEventListener('turbolinks:load', function () {
  new SweetScroll()

  if (document.querySelector("[data-has-errors='true']")) location.hash = '#contact-form'

  document.addEventListener(
    'click',
    function (event) {
      const el = event.target

      if (el.tagName === 'A' && !el.isContentEditable && el.host !== window.location.host) {
        el.setAttribute('target', '_blank')
      }
    },
    true
  )
})
