import { Controller } from "stimulus";
import Glide from "@glidejs/glide";

export default class extends Controller {
  connect() {
    if (this.element) {
      const slider = new Glide(this.element, {
        type: "carousel",
        autoplay: 10000,
        perView: 2,
        peek: { before: 0, after: 200 },
        gap: 60,
        breakpoints: {
          1024: {
            perView: 1,
            peek: { before: 0, after: 100 }
          },
          640: {
            perView: 1,
            peek: { before: 0, after: 0 }
          }
        }
      });

      slider.mount();
    }
  }
}
