import { Controller } from "stimulus";
import Glide from "@glidejs/glide";

export default class extends Controller {
  connect() {
    if (this.element) {
      const slider = new Glide(this.element, {
        type: "carousel",
        perView: 1,
        gap: 0,
        autoplay: 6000
      });

      slider.mount();
    }
  }
}
